import React from "react";

export default function Home() {
  const getAccessToken = () => {
    const url = `https://accounts.spotify.com/authorize?client_id=${encodeURIComponent(
      process.env.REACT_APP_SPOTIFY_CLIENT_ID
    )}&scope=user-top-read&response_type=token&redirect_uri=${
      process.env.REACT_APP_BASE_URL
    }/top`;

    window.location.href = url;
  };
  return (
    <div>
      <button onClick={() => getAccessToken()}>Login with Spotify</button>
    </div>
  );
}
