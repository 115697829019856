import classes from "./Track.module.css";
import React from "react";

export default function Track({
  name,
  rank,
  artists,
  albumArtwork,
  albumName,
  releaseDate,
}) {
  return (
    <div className={classes.root}>
      <img
        className={classes.artwork}
        src={albumArtwork[0].url}
        alt={`Artwork for ${albumName}`}
      />
      <div className={classes.info}>
        <div className={classes.rank}>{rank}.</div>
        <div className={classes.trackName}>{name}</div>
        <div className={classes.artistName}>{artists}</div>
        <div
          className={classes.albumName}
        >{`${albumName} (${releaseDate})`}</div>
      </div>
    </div>
  );
}
