import React from "react";
import Button from "../Button/Button";
import classes from "./Buttons.module.css";
export default function Buttons({
  range,
  setRange,
  mode,
  setMode,
  numResults,
  setNumResults,
}) {
  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.title}>Mode</div>
        <div className={classes.buttons}>
          <Button
            onClick={() => setMode("tracks")}
            text="Tracks"
            active={mode === "tracks"}
          />
          <Button
            onClick={() => setMode("artists")}
            text="Artists"
            active={mode === "artists"}
          />
        </div>
      </div>
      <div className={classes.break} />
      <div className={classes.section}>
        <div className={classes.title}>Range</div>
        <div className={classes.buttons}>
          <Button
            onClick={() => setRange("short_term")}
            text="Past 4 Weeks"
            active={range === "short_term"}
          />
          <Button
            onClick={() => setRange("medium_term")}
            text="Past 6 Months"
            active={range === "medium_term"}
          />
          <Button
            onClick={() => setRange("long_term")}
            text="All time"
            active={range === "long_term"}
          />
        </div>
      </div>
      <div className={classes.break} />
      <div className={classes.section}>
        <div className={classes.title}>Number of Results</div>
        <div className={classes.buttons}>
          <Button
            onClick={() => setNumResults(10)}
            text="Top 10"
            active={numResults === 10}
          />
          <Button
            onClick={() => setNumResults(25)}
            text="Top 25"
            active={numResults === 25}
          />
          <Button
            onClick={() => setNumResults(50)}
            text="Top 50"
            active={numResults === 50}
          />
        </div>
      </div>
    </div>
  );
}
