import React from "react";
import classes from "./Button.module.css";

export default function Button({ onClick, text, active }) {
  return (
    <button
      className={active ? ` ${classes.root} ${classes.active}` : classes.root}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
