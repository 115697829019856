import { Route, Switch } from "react-router";
import "./App.css";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Top from "./pages/Top/Top";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/top">
          <Top />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
