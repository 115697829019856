import React from "react";
import qs from "qs";
import classes from "./Top.module.css";
import Title from "../../components/Title/Title";
import Buttons from "../../components/Buttons/Buttons";
import Track from "../../components/Track/Track";
import Artist from "../../components/Artist/Artist";
import { Link } from "react-router-dom";

function getData(accessToken, timeRange, mode) {
  return fetch(
    `https://api.spotify.com/v1/me/top/${mode}?time_range=${timeRange}&limit=50`,
    {
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
      }),
    }
  ).then((x) => x.json());
}

function logout() {
  window.location.href = `${process.env.REACT_APP_BASE_URL}/`;
}

export default function Top() {
  const accessToken = qs.parse(window.location.hash, {
    ignoreQueryPrefix: true,
  })["#access_token"];

  const [range, setRange] = React.useState("long_term");
  const [mode, setMode] = React.useState("tracks");
  const [numResults, setNumResults] = React.useState(50);
  const [tracks, setTracks] = React.useState({});
  const [artists, setArtists] = React.useState({});

  React.useEffect(() => {
    let mounted = true;
    if (
      (mode === "artists" && !artists[range]) ||
      (mode === "tracks" && !tracks[range])
    ) {
      getData(accessToken, range, mode).then((d) => {
        if (mounted) {
          mode === "artists"
            ? setArtists({ ...artists, [range]: d })
            : setTracks({ ...tracks, [range]: d });
        }
      });
    }

    return () => (mounted = false);
  }, [range, mode]);

  const data = mode === "artists" ? artists[range] : tracks[range];

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link
          className={`${classes.headerText} ${classes.headerTextLink}`}
          to={"/about"}
        >
          <div>About</div>
        </Link>

        <div className={classes.headerText}>Spotify-Top</div>
        <div
          className={`${classes.headerText} ${classes.headerTextLink}`}
          onClick={() => logout()}
        >
          Logout
        </div>
      </div>
      <Title text={`My top ${numResults} ${mode}`} />
      <Buttons
        range={range}
        setRange={setRange}
        mode={mode}
        setMode={setMode}
        numResults={numResults}
        setNumResults={setNumResults}
      />
      <div className={classes.result}>
        {data ? (
          data.items?.slice(0, numResults)?.map((thing, index) => {
            console.log(thing);
            return mode === "tracks" ? (
              <Track
                key={index}
                rank={index + 1}
                name={thing?.name}
                artists={thing?.artists
                  ?.map((artist) => artist?.name)
                  .join(", ")}
                albumArtwork={thing?.album?.images}
                albumName={thing?.album?.name}
                releaseDate={thing?.album?.release_date.substring(0, 4)}
              />
            ) : (
              <Artist
                key={index}
                rank={index + 1}
                name={thing?.name}
                image={thing?.images[0]}
                link={thing?.external_urls.spotify}
                genre={thing?.genres.join(", ")}
              />
            );
          })
        ) : (
          <div>"loading"</div>
        )}
      </div>
    </div>
  );
}
