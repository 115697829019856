import React from "react";
import classes from "./Artist.module.css";

export default function Artist({ name, rank, image, link, genre }) {
  return (
    <div className={classes.root}>
      <img
        className={classes.artwork}
        src={image.url}
        alt={`Artwork for ${name}`}
      />
      <div className={classes.info}>
        <div className={classes.rank}>{rank}.</div>
        <a href={link}>
          <div className={classes.name}>{name}</div>
        </a>
        <div className={classes.genre}>{genre}</div>
      </div>
    </div>
  );
}
